import { useLayoutEffect, useState } from "react";
import useLang from "./../../../Shared/hooks/useLanguage";
import Commonservice from "Services/CommonService";
import { setWebInfo } from "Redux/Actions/Index";
import { setFaviconAndTitle } from "Utils/Common/CommonMethods";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
const ForgetPassword = () => {
  const { t } = useLang();
  const [logoUrl, setLogoUrl] = useState("");
  const [loginRequest, setLoginRequest] = useState<any>("");
  const [isSecurityQuestionVisible, setIsSecurityQuestionVisible] =
    useState(false);
  const dispatch = useDispatch();
  const getLogoUrl = () => {
    Commonservice.getLoginPageLogo()
      .then((res: any) => {
        setLogoUrl(res.data.logo);
        dispatch(
          setWebInfo({
            smartLogoUrl: res.data?.smartLogo,
            title: res.data?.labName,
          })
        );
        setFaviconAndTitle(
          res.data?.smartLogo,
          res.data?.labName || "TrueMed | The Smartest Way To AUTOMATE Your Lab"
        );
      })
      .catch((err: any) => {
        console.trace(err);
      });
  };

  const handleInputChange = (e: any) => {
    setLoginRequest(e.target.value);
  };
  const handleNextClick = () => {
    if (loginRequest) {
      setIsSecurityQuestionVisible(true);
    } else {
      toast.error(t("Enter Username/Email"), {
        position: "top-center",
      });
    }
  };
  useLayoutEffect(() => {
    getLogoUrl();
  }, []);
  return (
    <>
      {!isSecurityQuestionVisible ? (
        <div
          className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
          style={{
            backgroundImage: `url('${process.env.PUBLIC_URL}/media/illustrations/sketchy-1/14.png')`,
          }}
        >
          <div
            className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20"
            style={{ minHeight: "100vh" }}
          >
            <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
              <a href="#" className="mb-12 d-flex justify-content-center">
                {logoUrl && <img alt="Logo" src={logoUrl} className="h-45px" />}
              </a>
              <div className="text-center mb-10"></div>
              <div className="fv-row mb-10">
                <label className="form-label">
                  {t("Enter username or email to proceed")}
                </label>
                <input
                  placeholder={t("Username or email")}
                  required
                  name="username"
                  onChange={handleInputChange}
                  value={loginRequest}
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  autoComplete="off"
                />
              </div>

              <div className="d-flex justify-content-end gap-2">
                <Link to="/login" className="btn btn-sm btn-secondary ">
                  Back
                </Link>{" "}
                <button
                  className="btn btn-sm btn-primary "
                  onClick={handleNextClick}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column flex-center flex-column-fluid">
          <div className="d-flex flex-column flex-center p-10">
            <div className="card card-flush w-lg-650px py-5">
              <div className="card-body py-15 py-lg-15">
                <div className="text-center">
                  <span className="fw-bolder fs-2hx mb-4">
                    {t("Security Question")}
                  </span>
                </div>
                <div className="fw-semibold fs-6 text-gray-500 text-center">
                  {t("Please answer security questions in order to proceed")}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgetPassword;
